import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Grid, Icon, Image, Menu } from "semantic-ui-react";
import { UserContext } from "../NLayout";
import { default as config } from "../../constants/config.json";
import logo from "../../assets/img/Nlogo.svg";
import "./Header.css";
import { Link } from "react-router-dom";

import LazyLoad from "react-lazyload";

function Header() {
  const { t } = useTranslation();
  const { userData, userActions } = useContext(UserContext);

  // useEffect(() => {
  //   console.log(userData);
  // }, [userData]);

  const [visibleSidebar, setVisibleSidebar] = useState(false);
  function showSidebar(e) {
    e.preventDefault();
    setVisibleSidebar(true);
  }
  function hideSidebar(e) {
    setVisibleSidebar(false);
  }

  return (
    <>
      <header id="main-header2">
        <div className="center">
          <Link to="/">
            <Image
              src="./assets/img/logo_new.svg"
              alt={t("main.mainTitle")}
              title={t("main.mainTitle")}
              style={{ width: "220px" }}
              className="logo mb-2"
              loading="lazy"
              fill={"#fff"}
            />
          </Link>

          <div className="vector"></div>
          {/* <Image
          src='./assets/img/Vector_header.png'
          alt={t('main.mainTitle')}
          title={t('main.mainTitle')}
          className='logo'
          loading='lazy'
        /> */}
          <div className="flex-box">
            <span className="title">{t("header.have_questions")}</span>
            <a
              href="tel: +1-888-461-6120"
              className="mx-md-2 mr-2 subtitle w-100"
            >
              +1-888-461-6120
            </a>
          </div>
        </div>
      </header>

      {/* <LazyLoad once>
        <div id='main-sidebar' className={visibleSidebar ? 'show' : ''}>
          <Menu vertical fluid onClick={hideSidebar}>
            <Menu.Item as={Link} to='/'>
              <Link to='/'>
                <Image
                  src='./assets/img/logo.png'
                  alt={t('main.mainTitle')}
                  title={t('main.mainTitle')}
                  height='40px'
                  width='174px'
                  className='logo'
                  loading='lazy'
                />
              </Link>
            </Menu.Item>

            {!userData && (
              <>
                <Menu.Item onClick={userActions.openUserModal}>
                  <span>
                    <Icon name='sign-in' className='mr-2' color='teal' />
                    {t('main.login')}
                  </span>
                </Menu.Item>
                <Menu.Item onClick={userActions.openUserModal}>
                  <span>
                    <Icon name='user plus' className='mr-2' color='teal' />
                    {t('main.register')}
                  </span>
                </Menu.Item>
              </>
            )}

            {userData && (
              <>
                <Menu.Item as={Link} to='/panel/qoutes'>
                  <span>
                    <Icon name='copy outline' className='mr-2' color='teal' />
                    {t('panel.qoutes')}
                  </span>
                </Menu.Item>
                <Menu.Item as={Link} to='/panel/profile'>
                  <span>
                    <Icon name='user circle' className='mr-2' color='teal' />
                    {t('panel.profile')}
                  </span>
                </Menu.Item>
                <Menu.Item as={Link} to='/panel/news'>
                  <span>
                    <Icon
                      name='newspaper outline'
                      className='mr-2'
                      color='teal'
                    />
                    {t('panel.news')}
                  </span>
                </Menu.Item>
                <Menu.Item as={Link} to='/panel/calculators' key='calculators'>
                  <span>
                    <Icon name='calculator' className='mr-2' color='teal' />
                    {t('panel.calculators')}
                  </span>
                </Menu.Item>
                <Menu.Item as={Link} onClick={userActions.logout}>
                  <span>
                    <Icon name='sign-out' className='mr-2' color='teal' />
                    {t('main.logout')}
                  </span>
                </Menu.Item>
              </>
            )}
          </Menu>
        </div>
        <div
          id='sidebar-backdrop'
          className={visibleSidebar ? 'show' : ''}
          onClick={hideSidebar}
        ></div>
      </LazyLoad> */}
      <LazyLoad once>
        <div id="main-sidebar" className={visibleSidebar ? "show" : ""}>
          <Menu vertical fluid onClick={hideSidebar}>
            <Menu.Item as={Link} to="/">
              <Link to="/">
                <Image
                  src="./assets/img/logo.png"
                  alt={t("main.mainTitle")}
                  title={t("main.mainTitle")}
                  height="40px"
                  width="174px"
                  className="logo"
                  loading="lazy"
                />
              </Link>
            </Menu.Item>

            {!userData && (
              <>
                <Menu.Item onClick={userActions.openUserModal}>
                  <span>
                    <Icon name="sign-in" className="mr-2" color="teal" />
                    {t("main.login")}
                  </span>
                </Menu.Item>
                <Menu.Item onClick={userActions.openUserModal}>
                  <span>
                    <Icon name="user plus" className="mr-2" color="teal" />
                    {t("main.register")}
                  </span>
                </Menu.Item>
              </>
            )}

            {userData && (
              <>
                <Menu.Item as={Link} to="/panel/qoutes">
                  <span>
                    <Icon name="copy outline" className="mr-2" color="teal" />
                    {t("panel.qoutes")}
                  </span>
                </Menu.Item>
                <Menu.Item as={Link} to="/panel/profile">
                  <span>
                    <Icon name="user circle" className="mr-2" color="teal" />
                    {t("panel.profile")}
                  </span>
                </Menu.Item>
                <Menu.Item as={Link} to="/panel/news">
                  <span>
                    <Icon
                      name="newspaper outline"
                      className="mr-2"
                      color="teal"
                    />
                    {t("panel.news")}
                  </span>
                </Menu.Item>
                <Menu.Item as={Link} to="/panel/calculators" key="calculators">
                  <span>
                    <Icon name="calculator" className="mr-2" color="teal" />
                    {t("panel.calculators")}
                  </span>
                </Menu.Item>
                <Menu.Item as={Link} onClick={userActions.logout}>
                  <span>
                    <Icon name="sign-out" className="mr-2" color="teal" />
                    {t("main.logout")}
                  </span>
                </Menu.Item>
              </>
            )}
          </Menu>
        </div>
        <div
          id="sidebar-backdrop"
          className={visibleSidebar ? "show" : ""}
          onClick={hideSidebar}
        ></div>
      </LazyLoad>
    </>
  );
}

export default Header;
