import React, { createContext, useEffect, useRef, useState } from "react";
import Header from "./Header/NHeader";
import Footer from "./Footer/Footer";
import { useTranslation } from "react-i18next";
import { default as config } from "../constants/config.json";

import "semantic-ui-css/semantic.min.css";
import "../styles/index.scss";
import "../styles/ui.grid.css";
import "./Layout.css";

import { SemanticToastContainer, toast } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import { details, login, register, forgot } from "../crud/user";
import {
  Button,
  Form,
  Input,
  Modal,
  Tab,
  Dropdown,
  Grid,
  Segment,
  Icon,
  Image,
  Menu,
} from "semantic-ui-react";
import { storeGet, storeSet } from "../crud/storage";
// import PWAPrompt from "../components/PWA-Prompt/PWA-Prompt";
import { useHistory, useLocation } from "react-router-dom";
import LazyLoad from "react-lazyload";
import Slider from "../components/SliderQoute/index.js";
export const UserContext = createContext(null);

function LayoutEnter({ children }) {
  let history = useHistory();
  let location = useLocation();

  /* useEffect(() => {
    setTimeout(() => {
      var head = document.head;
      var link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      let stylePath =
        "https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css";
      link.href = stylePath;
      head.appendChild(link);
    }, 1000);
  }, []); */

  const { t } = useTranslation();

  return (
    <>
      <Grid
        columns="equal"
        style={{
          margin: "0px",
          height: "100%",
          background: "#105399",
          position: "fixed",
        }}
        inverted
      >
        <Grid.Row textAlign="center" style={{ padding: "0px", height: "100%" }}>
          <div className="topGradient"></div>
          <Grid.Column
            style={{ background: "#fff", zIndex: "2", height: "100vh" }}
          >
            <Header />

            <div className="childern">{children}</div>
            <div className="policy">
              <p className="policytext">Terms of use | Privacy policy </p>
            </div>
          </Grid.Column>
          <Grid.Column
            only=" computer"
            className="center"
            style={{ height: "100vh", zIndex: "2" }}
          >
            {" "}
            <Slider />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default LayoutEnter;
