import React, { createContext, useEffect, useRef, useState } from "react";
import "./index.css";
import { Button, Icon } from "semantic-ui-react";
const Slide = (props) => {
  /* The dot/bullet/indicator container */
  const container = {
    marginLeft: "100px",
    justifyContent: "left",
    display: "flex",
    marginTop: "-1rem",
  };

  return (
    <React.Fragment>
      <div class="ui top  top-svg">
        <div class="image-slider-main">
          <img
            src={props.image.link}
            alt="Sliderr_image"
            style={{ padding: 0, zIndex: 0 }}
          />
        </div>
      </div>
      <div class="ui grid ">
        <div class="sixteen wide column">
          {" "}
          <div className="fontTite font-svg">{props.image.title}</div>
        </div>
        <div class="sixteen wide column">
          {" "}
          <div className="fontDes font-svg">{props.image.des}</div>
        </div>
        <div class="sixteen wide column">
          <div style={container}>
            <span
              className="dot"
              style={{ background: props.id.id == 1 ? "white" : "" }}
            ></span>
            <span
              className="dot"
              style={{ background: props.id.id == 2 ? "white" : "" }}
            ></span>
            <span
              className="dot"
              style={{ background: props.id.id == 3 ? "white" : "" }}
            ></span>
          </div>
          <Icon
            name="right arrow"
            onClick={props.slideNext}
            style={{
              display: "flex",
              justifyContent: "right",
              marginTop: "-4rem",
              color: "white",
              width: "90%",
              zIndex: 9,
            }}
          />
        </div>
      </div>
      <div class="ui grid">
        <div class="sixteen wide column"></div>
      </div>
    </React.Fragment>
  );
};
const App = (props) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const slides = [
    {
      id: 1,
      title: "Life Insurance",
      des: "The steps are simple and only take a few minutes to complete.",
      link: "./assets/images/p1.svg",
    },
    {
      id: 2,
      title: "Critical Illness Insurance",
      des: "The steps are simple and only take a few minutes to complete.",
      link: "./assets/images/safari.png",
    },
    {
      id: 3,
      title: "Disability Insurance",
      des: "The steps are simple and only take a few minutes to complete.",
      link: "./assets/images/safari.png",
    },
  ];
  const slideNext = (e) => {
    setCurrentSlide((prev) => {
      return prev + 1 === slides.length ? 0 : currentSlide + 1;
    });
  };
  const slidePrev = (e) => {
    setCurrentSlide((prev) => {
      return prev === 0 ? slides.length - 1 : currentSlide - 1;
    });
  };

  return (
    <React.Fragment>
      <Slide
        image={slides[currentSlide]}
        id={slides[currentSlide]}
        slideNext={slideNext}
        slidePrev={slidePrev}
      />
    </React.Fragment>
  );
};

export default App;
