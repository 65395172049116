import React, { Suspense, useRef, useEffect, useState } from "react";
import {
  Grid,
  Segment,
  Image,
  Tab,
  Icon,
  Button,
  Form,
  Input,
  Modal,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { storeGet, storeSet } from "../../crud/storage";
import { details, login, register, forgot } from "../../crud/user";
import { SemanticToastContainer, toast } from "react-semantic-toasts";

import { default as config } from "../../constants/config.json";
import { useHistory, useLocation } from "react-router-dom";
import LazyLoad from "react-lazyload";
import LayoutEnter from "../../layout/Layoutenter";
import "semantic-ui-css/semantic.min.css";

import "./Register.css";
export default function Register() {
  let history = useHistory();
  let location = useLocation();

  const { t } = useTranslation();
  const [userData, setUserData] = useState(null);
  const [openedUserModal, setOpenedUserModal] = useState(false);
  const [userFormLoading, setUserFormLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState("0");
  const [submittingQoute, setSubmittingQoute] = useState(false);
  const [modalSuccessCallback, setModalSuccessCallback] = useState();
  const [userFormValues, setUserFormValues] = useState({
    name: "",
    mobile: "",
    email: "",
  });
  const [redirectToResult, setRedirectToResult] = useState(null);

  useEffect(() => {
    window.gtag("config", config.GoogleAnalytics.code, {
      page_path: window.location.hash,
    });

    //get the last form
    if (location.state) {
      setRedirectToResult(location.state);
    }
  }, [location]);

  const _userDetails = () => {
    details()
      .then((response) => {
        setUserData(response.data.success);
      })
      .catch((error) => {
        setSubmittingQoute(false);
        if (error.response.status === 401) {
          storeSet("user", null);
        } else {
          if (error.response.data && error.response.data.message) {
            toast({
              type: "error",
              title: error.response.data.message,
              animation: "bounce",
              time: 5000,
            });
          }
        }
      });
  };

  useEffect(() => {
    const userStorage = storeGet("user");
    if (userStorage && userStorage.token) {
      _userDetails();
    }
  }, []);

  const userFieldChanged = (e) => {
    let field = {};
    field[e.target.name] = e.target.value;
    let values = { ...userFormValues, ...field };
    setUserFormValues(values);
  };

  const userActions = {
    openUserModal: ({ successCallback, successParams }) => {
      setOpenedUserModal(true);
      setUserFormLoading(false);
      setModalSuccessCallback({
        successCallback: successCallback,
        successParams: successParams,
      });
    },
    logout: () => {
      storeSet("user", null);
      setUserData(null);
      history.push("/login");
    },
  };

  const loginEmailEl = useRef();

  const haveAccount = () => {
    if (redirectToResult) {
      //setGoToResult(true)
      history.push({
        pathname: "/login",
        state: {
          pathname: redirectToResult.pathname,
          formValues: redirectToResult.formValues,
          resultsProp: redirectToResult.resultsProp,
          loading: redirectToResult.loading,
          dontShowButtons: redirectToResult.dontShowButtons,
        },
      });
    } else {
      //setGoToResult(false)
      history.push("/login");
    }
  };

  const _register = () => {
    setUserFormLoading(true);
    register(userFormValues)
      .then((response) => {
        //if it has the previous result save it for the next login
        if (redirectToResult) {
          storeSet("last_result", redirectToResult);
        }
        setTimeout(
          () => {
            setUserFormLoading(false);

            if (response.data && response.data.success) {
              toast({
                type: "success",
                title:
                  "Please check your inbox and verify your email address and complete your registration",
                animation: "bounce",
                time: 5000,
              });
            }
          },
          userData ? 0 : 1000
        );

        //window.setTimeout(_userDetails, 2000);
      })
      .catch((error) => {
        setUserFormLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          toast({
            type: "error",
            title: error.response.data.error,
            animation: "bounce",
            time: 5000,
          });
        }
      });
  };

  const handleTabChange = (e, { activeIndex }) => setActiveIndex(activeIndex);

  return (
    <LayoutEnter>
      <SemanticToastContainer position="bottom-right" />
      <div class="centerdiv">
        <div class="ui two column centered grid">
          <Grid className="mx-0 padded">
            <Grid.Row
              className="section-wrapper insurance-type have-icon"
              style={{ padding: "0px" }}
            >
              <Grid.Column
                computer={14}
                tablet={16}
                mobile={14}
                style={{ margin: "0px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",

                    width: "100%",
                  }}
                >
                  <NavLink
                    to={`/`}
                    style={{
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <Icon name="left arrow" className="mx-3 " />
                    <p className="titletext">{t(`back_home`)}</p>
                  </NavLink>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="section-wrapper insurance-type have-icon">
              <Grid.Column
                computer={14}
                tablet={16}
                mobile={14}
                style={{ margin: "0px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",

                    width: "100%",
                  }}
                >
                  <p className="logintitle">{t(`Sign_up`)}</p>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="section-wrapper insurance-type have-icon">
              <Grid.Column
                computer={12}
                tablet={14}
                mobile={12}
                style={{ margin: "20px 0px", padding: "0px" }}
              >
                <Form onSubmit={_register} style={{ width: "100%" }}>
                  <Form.Field required>
                    <Input
                      className="logininputborder"
                      required
                      name="name"
                      icon="info"
                      iconPosition="left"
                      placeholder={t("main.name")}
                      value={userFormValues.name}
                      onChange={userFieldChanged}
                    />
                  </Form.Field>

                  <Form.Field required>
                    <Input
                      className="logininputborder"
                      required
                      name="mobile"
                      icon="mobile alternate"
                      iconPosition="left"
                      placeholder={t("main.mobile")}
                      value={userFormValues.mobile}
                      onChange={userFieldChanged}
                    />
                  </Form.Field>

                  <Form.Field>
                    <Input
                      className="logininputborder"
                      required
                      name="email"
                      icon="mail"
                      iconPosition="left"
                      placeholder={t("main.email")}
                      value={userFormValues.email}
                      onChange={userFieldChanged}
                    />
                  </Form.Field>

                  <Form.Field>
                    <Input
                      className="logininputborder"
                      required
                      name="password"
                      type="password"
                      icon="lock"
                      iconPosition="left"
                      placeholder={t("main.password")}
                      onChange={userFieldChanged}
                    />
                  </Form.Field>
                  <div className="regdivider">
                    <Button
                      type="button"
                      style={{ textAlign: "left", marginLeft: "-20px" }}
                      className="buttonforget"
                      disabled={userFormLoading}
                      onClick={() => {
                        haveAccount();
                      }}
                    >
                      {t("have_account")}
                    </Button>

                    <Button
                      type="submit"
                      primary
                      loading={userFormLoading}
                      disabled={userFormLoading}
                      className="buttonlogin"
                    >
                      {t("Sign_up")}
                    </Button>
                  </div>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    </LayoutEnter>
  );
}
