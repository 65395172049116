import React from "react";
import { Container, Grid, Divider } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import Layout from "../../layout/Layout";
import ScrollToTop from "../../components/Tools/ScrollToTop";
// import "../../styles/icon/svg-sprite.svg"

export default function Index() {
  const { t } = useTranslation();

  return (
    <>
      <ScrollToTop />
      <Layout>
        <div
          style={{
            position: "relative",
            paddingBottom: "1rem",
          }}
        >
          <Grid className="mx-0 mt-3 lnding_grid_mobile w-100 justify-content-center flex ">
            <Grid.Row>
              <Grid.Column
                className="w-100 justify-content-center flex "
                computer={16}
                tablet={16}
                mobile={16}
                style={{
                  padding: "0px",

                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  className="landing_logo "
                  src="./assets/img/logo.png"
                  alt="logo"
                  style={{
                    width: "200px",
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div className="w-100 justify-content-center flex flex-column mt-5 py-5 landing-page">
            <h1 className="landing_title">{t("termOfService.title")}</h1>

            <h1 className="landing_subtitle">{t("termOfService.date")}</h1>
          </div>
          <Container as="main" className="term-container">
            <div className="termCard  ">
              <p className=" text-left text-pre-wrap ">
                {t("termOfService.details")}
              </p>
            </div>
            <div>
              <div className="w-100 justify-content-center flex flex-column mt-5 py-5 ">
                <h3 className="heading-landing-gold">
                  {" "}
                  {t("termOfService.table")}
                </h3>
                {[
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                ].map((item) => (
                  <h3 className=" text-left text-pre-wrap px-4">
                    {t(`termOfService.header_${item}`)}
                  </h3>
                ))}
              </div>
              {/* 1. THE APPLICATION */}
              <div className="w-100 justify-content-center flex flex-column py-5 ">
                <p className="heading-landing-blue ">
                  {t("termOfService.header_1")}
                </p>
                <div className="termCard text-left ">
                  <p className="text-pre-wrap ">
                    {t("termOfService.describe_1")}
                  </p>
                </div>
              </div>
              {/* 2. SCOPE OF LICENSE */}
              <div className="w-100 justify-content-start flex flex-column py-5 ">
                <p className="heading-landing-blue ">
                  {t("termOfService.header_2")}
                </p>
                <div className="termCard  ">
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                    <p className="    text-pre-wrap ">
                      {t(`termOfService.describe_2_${item}`)}
                    </p>
                  ))}
                </div>
              </div>
              {/* 3. TECHNICAL REQUIREMENTS */}
              <div className="w-100 justify-content-start flex flex-column py-5 ">
                <p className="heading-landing-blue ">
                  {t("termOfService.header_3")}
                </p>
                <div className="termCard  ">
                  {[1, 2, 3].map((item) => (
                    <p className="    text-pre-wrap ">
                      {t(`termOfService.describe_3_${item}`)}
                    </p>
                  ))}
                </div>
              </div>
              {/* 4. TECHNICAL REQUIREMENTS */}
              <div className="w-100 justify-content-start flex flex-column py-5 ">
                <p className="heading-landing-blue ">
                  {t("termOfService.header_4")}
                </p>
                <div className="termCard  ">
                  {[1, 2].map((item) => (
                    <p className="    text-pre-wrap ">
                      {t(`termOfService.describe_4_${item}`)}
                    </p>
                  ))}
                </div>
              </div>
              {/* 5. USE OF DATA */}
              <div className="w-100 justify-content-center flex flex-column py-5 ">
                <p className="heading-landing-blue ">
                  {t("termOfService.header_5")}
                </p>
                <div className="termCard text-left ">
                  <p className="text-pre-wrap ">
                    {t("termOfService.describe_5")}
                  </p>
                </div>
              </div>
              {/* 6. USER-GENERATED CONTRIBUTIONS */}
              <div className="w-100 justify-content-start flex flex-column py-5 ">
                <p className="heading-landing-blue ">
                  {t("termOfService.header_6")}
                </p>
                <div className="termCard  ">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((item) => (
                    <p className="    text-pre-wrap ">
                      {t(`termOfService.describe_6_${item}`)}
                    </p>
                  ))}
                </div>
              </div>
              {/* 7. CONTRIBUTION LICENSE */}
              <div className="w-100 justify-content-center flex flex-column py-5 ">
                <p className="heading-landing-blue ">
                  {t("termOfService.header_7")}
                </p>
                <div className="termCard text-left ">
                  <p className="text-pre-wrap ">
                    {t("termOfService.describe_7")}
                  </p>
                </div>
              </div>
              {/* 8. LIABILITY */}
              <div className="w-100 justify-content-center flex flex-column py-5 ">
                <p className="heading-landing-blue ">
                  {t("termOfService.header_8")}
                </p>
                <div className="termCard text-left ">
                  <p className="text-pre-wrap ">
                    {t("termOfService.describe_8")}
                  </p>
                </div>
              </div>
              {/* 9. WARRANTY */}
              <div className="w-100 justify-content-start flex flex-column py-5 ">
                <p className="heading-landing-blue ">
                  {t("termOfService.header_9")}
                </p>
                <div className="termCard  ">
                  {[1, 2, 3, 4, 5, 6].map((item) => (
                    <p className="    text-pre-wrap ">
                      {t(`termOfService.describe_9_${item}`)}
                    </p>
                  ))}
                </div>
              </div>
              {/* 10. PRODUCT CLAIMS */}
              <div className="w-100 justify-content-start flex flex-column py-5 ">
                <p className="heading-landing-blue ">
                  {t("termOfService.header_10")}
                </p>
                <div className="termCard  ">
                  {[1, 2, 3].map((item) => (
                    <p className="    text-pre-wrap ">
                      {t(`termOfService.describe_10_${item}`)}
                    </p>
                  ))}
                </div>
              </div>
              {/* 11. LEGAL COMPLIANCE*/}
              <div className="w-100 justify-content-center flex flex-column py-5 ">
                <p className="heading-landing-blue ">
                  {t("termOfService.header_11")}
                </p>
                <div className="termCard text-left ">
                  <p className="text-pre-wrap ">
                    {t("termOfService.describe_11")}
                  </p>
                </div>
              </div>
              {/* 12. CONTACT INFORMATION*/}
              <div className="w-100 justify-content-center flex flex-column py-5 ">
                <p className="heading-landing-blue ">
                  {t("termOfService.header_12")}
                </p>
                <div className="termCard text-left ">
                  <p className="text-pre-wrap ">
                    {t("termOfService.describe_12")}
                  </p>

                  <p className="text-pre-wrap ">
                    {t("termOfService.describe_12_contact")}
                  </p>
                </div>
              </div>

              {/* 13. TERMINATION*/}
              <div className="w-100 justify-content-center flex flex-column py-5 ">
                <p className="heading-landing-blue ">
                  {t("termOfService.header_13")}
                </p>
                <div className="termCard text-left ">
                  <p className="text-pre-wrap ">
                    {t("termOfService.describe_13")}
                  </p>
                </div>
              </div>

              {/* 14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY*/}
              <div className="w-100 justify-content-center flex flex-column py-5 ">
                <p className="heading-landing-blue ">
                  {t("termOfService.header_14")}
                </p>
                <div className="termCard text-left ">
                  <p className="text-pre-wrap ">
                    {t("termOfService.describe_14")}
                  </p>
                </div>
              </div>

              {/* 15. INTELLECTUAL PROPERTY RIGHTS*/}
              <div className="w-100 justify-content-center flex flex-column py-5 ">
                <p className="heading-landing-blue ">
                  {t("termOfService.header_15")}
                </p>
                <div className="termCard text-left ">
                  <p className="text-pre-wrap ">
                    {t("termOfService.describe_15")}
                  </p>
                </div>
              </div>

              {/* 16. APPLICABLE LAW*/}
              <div className="w-100 justify-content-center flex flex-column py-5 ">
                <p className="heading-landing-blue ">
                  {t("termOfService.header_16")}
                </p>
                <div className="termCard text-left ">
                  <p className="text-pre-wrap ">
                    {t("termOfService.describe_16")}
                  </p>
                </div>
              </div>

              {/* 17. MISCELLANEOUS */}
              <div className="w-100 justify-content-start flex flex-column py-5 ">
                <p className="heading-landing-blue ">
                  {t("termOfService.header_17")}
                </p>
                <div className="termCard  ">
                  {[1, 2].map((item) => (
                    <p className="    text-pre-wrap ">
                      {t(`termOfService.describe_17_${item}`)}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <Divider className="w-100" />
          </Container>
        </div>
      </Layout>
    </>
  );
}
