import React from "react";
import { Container, Grid } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";

import Layout from "../../layout/Layout";
import FAQ from "./FAQ.js";
import ApplicationFeatures from "./ApplicationFeatures";
import Baner from "./Baner";
import OurCustomers from "./OurCustomers";
import OurServices from "./OurServices";
import Testimonials from "./Testimonials.js";
import OurFeatures from "./OurFeatures";
import WhyUs from "./WhyUs.js";
import { useMediaQuery } from "react-responsive";
//import CompanySlider from "../../components/CompanySlider/CompanySlider";
import ScrollToTop from "../../components/Tools/ScrollToTop";
// import "../../styles/icon/svg-sprite.svg"

export default function Home() {
  const { t } = useTranslation();

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1080 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1079 });
    return isTablet ? children : null;
  };

  return (
    <>
      <ScrollToTop />
      <Layout>
        <Grid className="mx-0 mt-3 lnding_grid_mobile w-100 jusfity-content-center flex ">
          <Grid.Row>
            <Grid.Column
              className="w-100 jusfity-content-center flex "
              computer={16}
              tablet={16}
              mobile={16}
              style={{
                padding: "0px",

                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                className="landing_logo "
                src="./assets/img/logo.png"
                alt="logo"
                style={{
                  width: "260px",
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Container
          as="main"
          className="py-4 lnding_grid_main"
          style={{ marginTop: "5em" }}
        >
          <div className="w-100 jusfity-content-center flex felx-column">
            <h1 className="landing_title">{t("main.landing_title")}</h1>

            <h1 className="landing_subtitle">{t("main.landing_subtitle")}</h1>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              alignSelf: "center",
            }}
          >
            <Desktop>
              <Grid
                className="mx-0 mt-3"
                style={{ maxWidth: "80rem", minWidth: "80rem" }}
              >
                <Grid.Row className="section-wrapper insurance-type have-icon gap-0">
                  {/* <Grid.Column
                    computer={4}
                    tablet={16}
                    mobile={16}
                    style={{ padding: "0px" }}
                  >
                    <NavLink to="/quote/life">
                      <div className="landingCard">
                        <img
                          alt={t("main.lifeInsurance")}
                          src="./assets/img/home/life_insurance.svg"
                          style={{
                            marginTop: "1.5rem",
                            width: "5.625rem",
                            height: "3.125rem",
                          }}
                        />
                        <div className="landingcardtitle">
                          <p className="landingcardtitletext">
                            {t("main.lifeInsurance")}
                          </p>
                          <p className="landingtitledec">
                            {t("main.lifeInsuranceDec")}
                          </p>
                        </div>
                      </div>
                      <input
                        required
                        className="input-section"
                        type="radio"
                        name="insuranceType"
                        value="life"
                      />
                    </NavLink>
                  </Grid.Column>

                  <Grid.Column
                    computer={4}
                    tablet={16}
                    mobile={16}
                    style={{ padding: "0px" }}
                  >
                    <NavLink to="/quote/CI">
                      <div className="landingCard">
                        <img
                          alt={t("main.CIInsurance")}
                          src="./assets/img/home/ciritcall_insurance.svg"
                          style={{
                            marginTop: "2em",
                            width: "5.625rem",
                            height: "3.125rem",
                          }}
                        />
                        <div className="landingcardtitle">
                          <p className="landingcardtitletext">
                            {t("main.CIInsurance")}
                          </p>
                          <p className="landingtitledec">
                            {t("main.CIInsuranceDec")}
                          </p>
                        </div>
                      </div>
                      <input
                        required
                        type="radio"
                        name="insuranceType"
                        value="CI"
                        className="input-section"
                      />
                    </NavLink>
                  </Grid.Column>

                  <Grid.Column
                    computer={4}
                    tablet={16}
                    mobile={16}
                    style={{ padding: "0px" }}
                  >
                    <NavLink to="/quote/DI">
                      <div className="landingCard">
                        <img
                          alt={t("main.DIInsurance")}
                          src="./assets/img/home/disability_insurance.svg"
                          style={{
                            marginTop: "2em",
                            width: "5.625rem",
                            height: "3.125rem",
                          }}
                        />
                        <div className="landingcardtitle">
                          <p className="landingcardtitletext">
                            {t("main.DIInsurance")}
                          </p>
                          <p className="landingtitledec">
                            {t("main.DIInsuranceDec")}
                          </p>
                        </div>
                      </div>

                      <input
                        required
                        type="radio"
                        name="insuranceType"
                        value="DI"
                        className="input-section"
                      />
                    </NavLink>
                  </Grid.Column> */}
                  <img
                    src="./assets/images/loading-video.svg"
                    alt="logo"
                    style={{
                      width: "50rem",
                      height: "28rem",
                    }}
                  />
                </Grid.Row>
              </Grid>
            </Desktop>
            {/* in the mobile */}
            <Tablet>
              <Grid className="mx-0 mt-3">
                <Grid.Row className="section-wrapper insurance-type have-icon gap-0">
                  {/* <Grid.Column
                    computer={5}
                    tablet={16}
                    mobile={16}
                    style={{ padding: "0px" }}
                  >
                    <NavLink to="/quote/life">
                      <div className="landingCard">
                        <img
                          alt={t("main.lifeInsurance")}
                          src="./assets/img/home/life_insurance.svg"
                          style={{
                            marginTop: "1.5rem",
                            width: "5.625rem",
                            height: "3.125rem",
                          }}
                        />
                        <div className="landingcardtitle">
                          <p className="landingcardtitletext">
                            {t("main.lifeInsurance")}
                          </p>
                          <p className="landingtitledec">
                            {t("main.lifeInsuranceDec")}
                          </p>
                        </div>
                      </div>
                      <input
                        required
                        className="input-section"
                        type="radio"
                        name="insuranceType"
                        value="life"
                      />
                    </NavLink>
                  </Grid.Column>

                  <Grid.Column
                    computer={5}
                    tablet={16}
                    mobile={16}
                    style={{ padding: "0px" }}
                  >
                    <NavLink to="/quote/CI">
                      <div className="landingCard">
                        <img
                          alt={t("main.CIInsurance")}
                          src="./assets/img/home/ciritcall_insurance.svg"
                          style={{
                            marginTop: "2em",
                            width: "5.625rem",
                            height: "3.125rem",
                          }}
                        />
                        <div className="landingcardtitle">
                          <p className="landingcardtitletext">
                            {t("main.CIInsurance")}
                          </p>
                          <p className="landingtitledec">
                            {t("main.CIInsuranceDec")}
                          </p>
                        </div>
                      </div>
                      <input
                        required
                        type="radio"
                        name="insuranceType"
                        value="CI"
                        className="input-section"
                      />
                    </NavLink>
                  </Grid.Column>

                  <Grid.Column
                    computer={5}
                    tablet={16}
                    mobile={16}
                    style={{ padding: "0px" }}
                  >
                    <NavLink to="/quote/DI">
                      <div className="landingCard">
                        <img
                          alt={t("main.DIInsurance")}
                          src="./assets/img/home/disability_insurance.svg"
                          style={{
                            marginTop: "2em",
                            width: "5.625rem",
                            height: "3.125rem",
                          }}
                        />
                        <div className="landingcardtitle">
                          <p className="landingcardtitletext">
                            {t("main.DIInsurance")}
                          </p>
                          <p className="landingtitledec">
                            {t("main.DIInsuranceDec")}sss
                          </p>
                        </div>
                      </div>

                      <input
                        required
                        type="radio"
                        name="insuranceType"
                        value="DI"
                        className="input-section"
                      />
                    </NavLink>
                  </Grid.Column> */}
                  <img
                    src="./assets/images/loading-video.svg"
                    alt="logo"
                    style={{
                      width: "50rem",
                      height: "28rem",
                    }}
                  />
                </Grid.Row>
              </Grid>
            </Tablet>
          </div>
        </Container>
        <div className="bottom-svg">
          <img
            alt="landingpage"
            style={{ width: "100%", height: "100%" }}
            src="./assets/img/home/landingpage.svg"
          />
        </div>
        <Container
          as="main"
          className="py-4 container_main1"
          style={{ marginTop: "14em" }}
        >
          <OurCustomers />
          <OurFeatures />
          <ApplicationFeatures />
          <OurServices />
          <WhyUs />
          <Testimonials />
        </Container>
        <Baner />
        <Container
          as="main"
          className=" container_main2"
          style={{ marginTop: "4.5rem" }}
        >
          <FAQ />
        </Container>
      </Layout>
    </>
  );
}
