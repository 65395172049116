/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Switch, withRouter, Route } from "react-router-dom";
// import EmailVerify from "../components/EmailVerify/EmailVerify";
// import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import Layout from "../layout/Layout";
import NLayout from "../layout/NLayout";
//import Home from "../pages/Home/Home";
import Lifelanding from "../pages/Lifelanding/Home";
import CIlanding from "../pages/CIlanding/Home";
import DIlanding from "../pages/DIlanding/Home";
import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import Recovery from "../pages/Recovey/Recovery";
import Complete from "../components/CompleteQuote/CompleteQuote";
import TermOfService from "../pages/TermOfService/index";
import PrivacyPolicy from "../pages/PrivacyPolicy/index";
// import Panel from "../pages/Panel/Panel";
// import Qoute from "../pages/Qoute/Qoute";

const Panel = React.lazy(() => import("../pages/Panel/Panel"));
const Qoute = React.lazy(() => import("../pages/Qoute/Qoute"));
const ForgotPassword = React.lazy(() =>
  import("../components/ForgotPassword/ForgotPassword")
);
const EmailVerify = React.lazy(() =>
  import("../components/EmailVerify/EmailVerify")
);
 const Home = React.lazy(() => import('../pages/Home/Home'));

export const Routes = withRouter(({ history }) => {
  return (
    <>
      <NLayout>
        <Switch>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}

          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/life-insurance" exact>
            <Lifelanding />
          </Route>
          <Route path="/critical-insurance" exact>
            <CIlanding />
          </Route>
          <Route path="/Disability-insurance" exact>
            <DIlanding />
          </Route>
          <Route path="/panel">
            <Panel />
          </Route>

          <Route path="/forgot_password">
            <ForgotPassword />
          </Route>

          <Route path="/email/verify/:id/:hash">
            <EmailVerify />
          </Route>

          <Route path="/quote">
            <Qoute />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/recovery">
            <Recovery />
          </Route>
          <Route path="/Complete_quote">
            <Complete />
          </Route>
          <Route path="/Term-of-use">
            <TermOfService />
          </Route>
          <Route path="/Privacy-policy">
            <PrivacyPolicy />
          </Route>
        </Switch>
      </NLayout>
    </>
  );
});
