import React, { Suspense,useRef, useEffect, useState } from 'react';
import { Grid, Segment, Image, Tab, Icon, Button, Form, Input, Modal, } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { storeGet, storeSet } from '../../crud/storage';
import { details, login, register, forgot } from '../../crud/user';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';

import { default as config } from '../../constants/config.json';
import { useHistory, useLocation } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import LayoutEnter from '../../layout/Layoutenter';
import 'semantic-ui-css/semantic.min.css';



export default function Recovey() {
  let history = useHistory();
  let location = useLocation();

  const { t } = useTranslation();
  const [userData, setUserData] = useState(null);
  const [openedUserModal, setOpenedUserModal] = useState(false);
  const [userFormLoading, setUserFormLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState('0');
  const [submittingQoute, setSubmittingQoute] = useState(false);
  const [modalSuccessCallback, setModalSuccessCallback] = useState();
  const [userFormValues, setUserFormValues] = useState({
    name: '',
    mobile: '',
    email: '',
  });

  useEffect(() => {
    window.gtag('config', config.GoogleAnalytics.code, {
      page_path: window.location.hash,
    });
  }, [location]);

  const _userDetails = () => {
    details()
      .then((response) => {
        setUserData(response.data.success);
      })
      .catch((error) => {
        setSubmittingQoute(false);
        if (error.response.status === 401) {
          storeSet('user', null);
        } else {
          if (error.response.data && error.response.data.message) {
            toast({
              type: 'error',
              title: error.response.data.message,
              animation: 'bounce',
              time: 5000,
            });
          }
        }
      });
  };

  useEffect(() => {
    const userStorage = storeGet('user');
    if (userStorage && userStorage.token) {
      _userDetails();
    }
  }, []);

  const userFieldChanged = (e) => {
    let field = {};
    field[e.target.name] = e.target.value;
    let values = { ...userFormValues, ...field };
    setUserFormValues(values);
  };

  const userActions = {
    openUserModal: ({ successCallback, successParams }) => {
      setOpenedUserModal(true);
      setUserFormLoading(false);
      setModalSuccessCallback({
        successCallback: successCallback,
        successParams: successParams,
      });
    },
    logout: () => {
      storeSet('user', null);
      setUserData(null);
      history.push('/login');
    },
  };

  const loginEmailEl = useRef();
  const _forgot = (e) => {
    e.preventDefault();
    if (userFormValues.email) {
      setUserFormLoading(true);
      forgot(userFormValues.email)
        .then((response) => {
          setUserFormLoading(false);
          toast({
            type: 'success',
            title: response.data.msg,
            animation: 'bounce',
            time: 5000,
          });
        })
        .catch((error) => {
          setUserFormLoading(false);
          if (error.response.data && error.response.data.message) {
            toast({
              type: 'error',
              title: error.response.data.message,
              animation: 'bounce',
              time: 5000,
            });
          }
        });
    } else {
      loginEmailEl.current.focus();
    }
  };
  const backToLogin=()=>{
    history.push('/login');
  }


  const handleTabChange = (e, { activeIndex }) => setActiveIndex(activeIndex);

  return (
    <LayoutEnter>

<SemanticToastContainer position='bottom-right' />
      <div class='centerdiv'>
        <div class='ui two column centered grid'>
          <Grid className='mx-0 padded'>
            <Grid.Row
              className='section-wrapper insurance-type have-icon'
              style={{ padding: '0px' }}
            >
              <Grid.Column
                  computer={12}
                  tablet={14}
                  mobile={12}
                style={{ margin: '0px' }}
              >
                  <div
                       
                       style={{
                         display: 'flex',
                         justifyContent: 'left',
                       
                         width:'100%'
                       }}
                     >
                         <NavLink to={`/`} style={{
                         display: 'flex',
                         justifyContent: 'left',
                       
                       }}>
                       <Icon name='left arrow' className='mx-3 ' />
                       <p className='titletext'>
                         {t(`back_home`)}
                       </p>
                       </NavLink>
                     </div>
                     
                
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className='section-wrapper insurance-type have-icon'>
              <Grid.Column
               computer={12}
               tablet={14}
               mobile={12}
                style={{ margin: '0px' }}
                
              >
            <div
                    
                       style={{
                         display: 'flex',
                         justifyContent: 'left',
                          
                         
                         width:'100%'
                       }}
                     >
           <p className='logintitle'>
                         {t(`main.forgot`)}
                       </p>
                       </div>
                   
                     
              
              </Grid.Column>
            </Grid.Row>
          
            <Grid.Row className='section-wrapper insurance-type have-icon'>
              <Grid.Column
                  computer={10}
                  tablet={12}
                  mobile={10}
                style={{ margin: '20px 0px' ,padding:'0px'}}
              >
           
           <Form style={{  width:'100%',  }}>
                          <Form.Field required>
                            <Input
                             className='logininputborder'
                              ref={loginEmailEl}
                              required
                              name='email'
                              type='email'
                              icon='mail'
                              iconPosition='left'
                              placeholder={t('main.email')}
                              value={userFormValues.email}
                              onChange={userFieldChanged}
                            />
                          </Form.Field>

                         
                          <div className='logindivider'>
                          <Button
                            type='button'
                            style={{ textAlign:'left', marginLeft:'-20px'}}
                           className='buttonforget' 
                            disabled={userFormLoading}
                         
                            onClick={() => {
                              backToLogin()
                            }}

                          >
                            {t('back_login')}
                          </Button>

                          <Button
                            type='submit'
                            primary
                            loading={userFormLoading}
                            disabled={userFormLoading}
                            className="buttonlogin"
                            onClick={_forgot}
                            style={{ width:'fit-content'}}
                          >
                          
                            {t('Send_rest_link')}
                          </Button>
                          </div>
                        </Form>
                   
                  
              
              </Grid.Column>
            </Grid.Row>
          
          </Grid>

         
        </div>
      </div>
    </LayoutEnter>
  );
}
