import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import "../../../app/pages/Qoute/Qoute.css";
export default function CompleteQuote() {
  const [loadingChange, setLoadingChange] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  let location = useLocation();

  useEffect(
    () => {
      let timer1 = setTimeout(() => setLoadingChange(true), 9 * 1000);

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        clearTimeout(timer1);
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  );

  useEffect(() => {
    if (loadingChange) {
      history.push("/panel/qoutes");
    }
  }, [loadingChange]);

  useEffect(() => {
    //get the last form
    if (!location.state) {
      history.push("/");
    }
  }, [location]);

  return (
    <>
      <div
        style={{
          justifyContent: "center",
          justifyItem: "center",
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          alignItems: "center",
          padding: "0rem 3.1rem",
        }}
      >
        <div className="style-center">
          {" "}
          <img
            className="complete_gif "
            alt="landing_logo"
            src="./assets/img/Get_coverd.gif"
          />
        </div>
        <img
          className="landing_logo  complete_logo"
          alt="logo"
          src="./assets/img/logo.png"
        />
        <p
          className="h8 style-center complete_message "
          style={{ textAlign: "center" }}
        >
          {" "}
          {t("complete_page.message")}
        </p>
        <p className="landing_title style-center">
          {" "}
          {t("complete_page.title")}
        </p>
        <NavLink to={`/panel/qoutes`}>
          <p className="subtext style-center mt-3 back-to-home">
            {" "}
            {t("complete_page.back_to_home")}
          </p>
        </NavLink>
      </div>
    </>
  );
}
