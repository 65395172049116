import React, { Suspense, useEffect, useState } from "react";
import { Grid, Card, Feed } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Rating } from "semantic-ui-react";

import Carousel from "react-grid-carousel";

export default function Testimonials() {
  const { t } = useTranslation();
  const [data, setData] = useState([
    {
      author_name: "Sam",
      author_url:
        "https://www.google.com/maps/contrib/109113526377551269139/reviews",
      language: "en",
      original_language: "en",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a/AItbvmlZELupTKoDQNgb2r3cK7kmCEHJlLOS22s6upK6=s128-c0x00000000-cc-rp-mo-ba2",
      rating: 5,
      relative_time_description: "4 months ago",
      text: "What a great company. That was my first time working with Apluswealth and I got their dental insurance among their many other options like life insurance for myself and my family. They were so understanding, helpful and they answer all my questions in a polite and respectful manner. Also, they have other insurance too and maybe in future i will get those too cause why not…! Well done. Apluswealth company Is 100% recommended.",
      time: 1650595360,
      translated: false,
    },
    {
      author_name: "SAhar KH71",
      author_url:
        "https://www.google.com/maps/contrib/109844231140598350849/reviews",
      language: "en",
      original_language: "en",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a/AItbvmnl9JcpMhJiuvKm7TXigoXGjSqLTEzaMt5llPEu=s128-c0x00000000-cc-rp-mo",
      rating: 5,
      relative_time_description: "4 months ago",
      text: "I encountered with the Apluswealth when I was looking for a good company for life insurance.\nIt provided me with an exact\nrecommendation of my insurance\nneeds based on a simple\nquestionnaire. It did not try to\noversell me and gave me the best\npossible price for a policy. I highly recommended to all.",
      time: 1650594109,
      translated: false,
    },
    {
      author_name: "Akram Torkashvand",
      author_url:
        "https://www.google.com/maps/contrib/104755259051985827599/reviews",
      language: "en",
      original_language: "en",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a/AItbvmn1KtJAAg4uhpjP2P6HlwpAjiKgKaD9YXWyfxbg=s128-c0x00000000-cc-rp-mo",
      rating: 5,
      relative_time_description: "9 months ago",
      text: "I am quite glad to have chosen Apluswealth Insurance Services for my life, critical illness and disability insurance. Based on my experience as a customer and for a number of reasons I recommend this company to anyone who needs any personal insurance and looking for a good company. With Apluswealth, you can get quickly a quote, register easily and hassle-free; their software is very user-friendly, the policy is transparent without any hidden fees, and their rate is very reasonable comparing with other competitors in the industry.  Lastly, their agents are generally well-trained and responsive.  I, personally had interactions with Ali Yousefi in the past, who is very, courteous, thorough, and conscientious. He double-checks everything making sure that the customer was understood properly and the issue addressed.",
      time: 1639625627,
      translated: false,
    },
    {
      author_name: "Sasha unkuras",
      author_url:
        "https://www.google.com/maps/contrib/117744146205053117875/reviews",
      language: "en",
      original_language: "en",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a/AItbvmn1PRxdCOWuiUl93ll2t14aU0SRgDPbb3yK8ACU=s128-c0x00000000-cc-rp-mo",
      rating: 5,
      relative_time_description: "4 months ago",
      text: "I've had a very positive experience with Apluswealth company!\nI had contacted several insurances companies, but I got most reasonable quote for my life insurance from Apluswealth! I also would like to say Huge thanks to Ashkon who answered all my questions patiently and went above and beyond!",
      time: 1650595229,
      translated: false,
    },
    {
      author_name: "Ani Parsa",
      author_url:
        "https://www.google.com/maps/contrib/108000263174121998661/reviews",
      language: "en",
      original_language: "en",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a/AItbvmlK9lwdhQzJcSJnxqsxc3i_tZgq6ZiugSyfB8sp=s128-c0x00000000-cc-rp-mo",
      rating: 5,
      relative_time_description: "4 months ago",
      text: "Very happy that I choose Apluswealth to help me with my family life insurance planning and policies. Thank you Ali for being so helpful and patience. We have great rates and solid policies thanks to you.",
      time: 1650591150,
      translated: false,
    },
    {
      author_name: "Sam",
      author_url:
        "https://www.google.com/maps/contrib/109113526377551269139/reviews",
      language: "en",
      original_language: "en",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a/AItbvmlZELupTKoDQNgb2r3cK7kmCEHJlLOS22s6upK6=s128-c0x00000000-cc-rp-mo-ba2",
      rating: 5,
      relative_time_description: "4 months ago",
      text: "What a great company. That was my first time working with Apluswealth and I got their dental insurance among their many other options like life insurance for myself and my family. They were so understanding, helpful and they answer all my questions in a polite and respectful manner. Also, they have other insurance too and maybe in future i will get those too cause why not…! Well done. Apluswealth company Is 100% recommended.",
      time: 1650595360,
      translated: false,
    },
  ]);
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1080 });
    return isDesktop ? children : null;
  };

  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1079 });
    return isTablet ? children : null;
  };

  useEffect(() => {
    const sendRequest = async () => {
      try {
        const response = await fetch(
          "https://maps.googleapis.com/maps/api/place/details/json?key=AIzaSyCk115RriTVIb6KVki9WeMaDc4AxgGsryQ&placeid=ChIJTUaTBCXL1IkRPlxz73c1x3c/",
          {
            method: "GET",

            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "*",
            },
            crossDomain: true,
          }
        );

        if (!response.ok) {
          throw new Error("ERORRR");
        }

        const responseData = await response.json();
        if (responseData.reviews && responseData.reviews.length > 2) {
          setData(responseData.reviews);
        }
      } catch (err) {
        console.log(err);
      }
    };
    sendRequest();
  }, []);

  return (
    <>
      <Desktop>
        <div
          className="flex  w-100 flex-column"
          style={{
            position: "relative",
          }}
        >
          <div
            className=" w-100 flex d-flex justify-content-center margin-auto"
            style={{
              marginTop: "4rem",
              alignSelf: "center",
            }}
          >
            <div className="mt-5  w-100 flex flex-column justify-content-center ">
              <h3 className="text-center heading-landing-gold">
                {" "}
                {t("Testimonials.title")}
              </h3>
              <div className="flex d-flex justify-content-center text-center w-100">
                <h3
                  className="text-center heading-landing-blue pr-3"
                  style={{ marginTop: "-10px" }}
                >
                  {t("Testimonials.sub_title")}
                </h3>
                <img
                  alt="check"
                  className=" d-none d-md-block"
                  style={{ marginTop: "-80px" }}
                  src="./assets/img/landing/check.svg"
                />
              </div>

              <h3
                className=" text-center text-landing pr-3"
                style={{ marginTop: "-10px", width: "100%" }}
              >
                {t("Testimonials.describe")}
              </h3>

              <div className="w-100 mt-5 text-center">
                <h3
                  className="heading-landing-gold "
                  style={{ fontSize: "24px" }}
                >
                  Google reviews
                </h3>
                <div className="flex d-flex justify-content-center ">
                  <Rating
                    defaultRating={5}
                    maxRating={5}
                    icon="star"
                    size="huge"
                    disabled
                    className="mb-4"
                  ></Rating>
                  <p
                    className="heading-landing-gold ml-2"
                    style={{ marginTop: "-2px" }}
                  >
                    4.9
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100  " style={{ maxHight: "100px" }}>
            <Carousel
              scrollSnap={true}
              className="w-100  "
              responsiveLayout={[
                {
                  breakpoint: 1400,
                  cols: 3,
                },
                {
                  breakpoint: 1200,
                  cols: 2,
                },
              ]}
              cols={3}
              rows={1}
              gap={8}
              loop
            >
              {data.map((item) => (
                <Carousel.Item>
                  <div
                    style={{
                      marginTop: "10px",
                      width: "100%",
                      border: "1px solid rgb(209, 215, 223, 0.7)",
                      background: "#fff",
                      borderRadius: "8px",
                      padding: "1rem",
                      hight: "300px",
                      marginLeft: "4px",
                    }}
                  >
                    <div className="flex my-3 mx-3  d-flex justify-content-start justify-items-center align-items-center">
                      <img
                        alt="profile"
                        src={item.profile_photo_url}
                        width={"60px"}
                      />
                      <div className="  mx-3  ">
                        <div className="w-100 flex-column">
                          <h5>{item.author_name}</h5>
                          <Rating
                            defaultRating={item.rating}
                            maxRating={5}
                            icon="star"
                            size="large"
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="Carousel  " l>
                      <h3 className="text-landing pl-1  text-left">
                        {item.text}
                      </h3>
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          {/* eclipse */}
          <div className="eclipse_testimonials"></div>
        </div>
      </Desktop>
      <Tablet>
        <div
          className="flex w-100 flex-column justify-content-center py-4 px-4 "
          style={{
            background:
              "linear-gradient(to bottom, #f9f9fb,#f9f9fb, #f6f7f9, #f6f7f9, #f3f4f7, #f3f4f7)",
            marginBottom: "1.8rem",
          }}
        >
          <div className=" w-100 flex flex-column justify-content-center ">
            <div className="mt-3  w-100">
              <h3 className="heading-landing-gold">
                {" "}
                {t("Testimonials.title")}
              </h3>
              <div className="flex d-flex w-100 justify-content-center ">
                <h3
                  className="heading-landing-blue flex d-flex w-100 justify-content-center"
                  style={{
                    marginTop: "-10px",
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {t("Testimonials.sub_title")}
                </h3>
              </div>

              <h3
                className="text-landing "
                style={{
                  marginTop: "5px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {t("Testimonials.describe")}
              </h3>
            </div>
          </div>

          <div className="w-100 mt-5 text-center">
            <h3 className="heading-landing-gold " style={{ fontSize: "24px" }}>
              Google review
            </h3>
            <div className="flex d-flex justify-content-center ">
              <Rating
                defaultRating={5}
                maxRating={5}
                icon="star"
                size="huge"
                disabled
                className="mb-4"
              ></Rating>
              <p
                className="heading-landing-gold ml-2"
                style={{ marginTop: "-2px" }}
              >
                4.9
              </p>
            </div>
          </div>
          <div className="w-100 ">
            <Carousel
              className="w-100  "
              hideArrow={false}
              scrollSnap={true}
              showDots={true}
              cols={1}
              rows={1}
              gap={8}
              loop={true}
              autoplay={2000}
            >
              {data.map((item) => (
                <Carousel.Item>
                  <div
                    style={{
                      marginTop: "10px",
                      width: "100%",
                      border: "1px solid rgb(209, 215, 223, 0.7)",
                      background: "#fff",
                      borderRadius: "8px",
                      padding: "1.1rem",
                      hight: "300px",
                    }}
                  >
                    <div className="flex my-3 mx-3  d-flex justify-content-start justify-items-center align-items-center">
                      <img
                        alt="profile"
                        src={item.profile_photo_url}
                        width={"60px"}
                      />
                      <div className="  mx-3  ">
                        <div className="w-100 flex-column">
                          <h5>{item.author_name}</h5>
                          <Rating
                            defaultRating={item.rating}
                            maxRating={5}
                            icon="star"
                            size="large"
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="Carousel  ">
                      <h3 className="text-landing pl-1  text-left">
                        {item.text}
                      </h3>
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </Tablet>
    </>
  );
}
